/* eslint-disable no-await-in-loop */

export class APIClientTools {
  public static readonly API_TIMEOUT_MS = +(process.env.VUE_APP_API_TIMEOUT_MS as string);

  // Maximum number of retries before the timeout short-circuits. Network latency may further limit the number of retries.
  public static readonly MAXIMUM_RETRIES = 4;

  /**
   * The API calls are short-circuited by API_TIMEOUT_MS. Within this window, we can retry the call a few times
   * while backing off exponentially. This property defines the backoff scale factor which will yield the
   * appropriate number of retries within the time window (disregarding the time the Promise actually takes to resolve/reject).
   */
  private static readonly backoffScaleFactorMs: number = (() => {
    if (APIClientTools.MAXIMUM_RETRIES < 1) {
      throw new Error('Retries needs to at least 1');
    }
    const aMax = APIClientTools.API_TIMEOUT_MS / (2 ** APIClientTools.MAXIMUM_RETRIES - 1); // Geometric sum
    return Math.floor(aMax);
  })();

  /**
   * Using exponential backoff, keep trying the given action until the configured timeout is reached (promise will short-circuit).
   * - If timeout is reached, and the call never resolved, a custom error message will be given.
   * - If timeout is reached, and the call had errored out prior to retry, that same error message will be given.
   *
   * The short-circuited Promise may later resolve on its own. This is useful eg. when the response might come too
   * late to be reflected in the UI, but we still want to update its value into a cache for next time. Consider adding
   * a console.info into the promise chain, since debugging can later be hard due to the split paths of execution.
   *
   * NOTE: It would be bad practice to use this method with idempotent actions, since the number of retries
   * is not deterministic.
   */
  public static withRetries<T>(promiseFactory: () => Promise<T>, timeoutErrorMessage = `Waited over ${this.API_TIMEOUT_MS}ms`): Promise<T> {
    let latestError: Error;
    let abandoned = false;

    const retryUntilAbandoned = async (): Promise<T> => {
      let retriesCount = -1;
      do {
        retriesCount += 1;
        try {
          return await promiseFactory();
        } catch (e) {
          latestError = e;
          const exponentialBackoff = (2 ** retriesCount) * this.backoffScaleFactorMs;
          await new Promise<void>((resolve) => setTimeout(resolve, exponentialBackoff));
        }
      } while (!abandoned); // Retire the retrier if it was short-circuited

      throw new Error('Promise intentionally short-circuited'); // Unobserved, since Promise.race will have omitted this promise by now
    };

    // Now, short-circuit the promise so that it doesn't keep retrying indefinitely.
    return Promise.race([
      retryUntilAbandoned(),

      new Promise((resolve, reject) => setTimeout(() => {
        // If the API call never resolved, it's a timeout error likely due to network latency.
        // Otherwise, the API call is the real error culprit.
        const errorToReport = latestError || new Error(timeoutErrorMessage);

        abandoned = true;
        return reject(errorToReport);
      }, this.API_TIMEOUT_MS)) as Promise<never>,
    ]);
  }
}
