export const vuetifyOptions = {
  theme: {
    themes: {
      // Theme chosen in App.vue
      light: {
        primary: '#34573e', // Dark slate gray
        secondary: '#08aa93', // Dark cyan

        background: '#F8F8F8', // Crucially not plain white

        error: '#FF8A80',
        warning: '#f9a825',
      },
      dark: {
        primary: '#34573e', // Dark slate gray
        secondary: '#08aa93', // Dark cyan

        background: '#121212',

        error: '#720900',
        warning: '#7f5003',
      },
    },
    options: {
      customProperties: true,
    },
  },
};
