import Vue, { PropType } from 'vue';
import { CountryCode } from '@/types/CountryCode';
import { CardPriority } from '@/types/CardPriority';
import { CardType } from '@/types/CardType';
import CountryCard from '@/components/CountryCard.vue';
import CountryName from '@/components/CountryName.vue';

export const ViewMixin = Vue.extend({

  computed: {
    // Overridden by component, in case it has more complex loading maneuvers
    allSet(): boolean {
      return true;
    },
  },

  watch: {
    allSet: {
      handler(newOne: boolean) {
        if (newOne) {
          // Let the pre-renderer know that this view is all set to be snapshotted
          document.dispatchEvent(new Event('trawel-rendered'));
        }
      },
      immediate: true,
    },
  },

});
