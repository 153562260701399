import { notEmpty } from '@/types/notEmpty';
import { Clock } from '@/modules/time/Clock';
import { DirectionalJetlag } from '@/types/time/DirectionalJetlag';
import { ColloquialHoursOfDay, HourOfDay } from '@/types/time/ColloquialHoursOfDay';
import { ColloquialTimeOfDay } from '@/types/time/ColloquialTimeOfDay';
import { InclinationTowardsLight } from '@/types/time/InclinationTowardsLight';
import { RelativeTimezone } from '@/types/time/RelativeTimezone';
import { JetlagInfo } from '@/types/time/JetlagInfo';
import { BedtimeShift } from '@/types/time/BedtimeShift';
import { LightInfo } from '@/types/time/LightInfo';
import { LightRecommendation } from '@/types/time/LightRecommendation';

export class CircadianClock {
  /**
   * Don't change these static parameters, they are from the following source:
   * Waterhouse, Jim, et al. "Jet lag: trends and coping strategies." The Lancet 369.9567 (2007): 1117-1129.
   * */

  public static RECOMMENDATION_WINDOW_HOURS = 6;

  public static EAST_WEST_BOUNDARY = -14;

  public static LIGHT_INCLINATION_RELATIVE_TO_HOURS_SHIFTED = {
    west: { avoidFromHour: -19, seekFromHour: -3 },
    east: { avoidFromHour: -3, seekFromHour: -19 },
  }

  public static recommendBedtimeShift(minutesCrossed: number): BedtimeShift | undefined {
    const m = Clock.shiftClockMinutes(minutesCrossed, this.EAST_WEST_BOUNDARY);

    if (m === 0) {
      return undefined;
    }

    const preparatoryBedtimeShift = m < 0 ? BedtimeShift.DELAY_BEDTIME : BedtimeShift.SLEEP_EARLIER;
    return preparatoryBedtimeShift;
  }

  /**
   * @param minutesCrossed Number of minutes that the user has traversed across timezones, to the East (negative is West)
   */
  public static recommendLightExposureTimes(minutesCrossed: number) {
    const m = Clock.shiftClockMinutes(minutesCrossed, this.EAST_WEST_BOUNDARY);
    const mOnReturn = Clock.shiftClockMinutes(-minutesCrossed, this.EAST_WEST_BOUNDARY);
    const windowHours = this.RECOMMENDATION_WINDOW_HOURS;

    let avoidFromMinute: number;
    let seekFromMinute: number;

    if (m <= -3 * 60) {
      avoidFromMinute = Clock.shiftClockMinutes(m + (this.LIGHT_INCLINATION_RELATIVE_TO_HOURS_SHIFTED.west.avoidFromHour * 60));
      seekFromMinute = Clock.shiftClockMinutes(m + (this.LIGHT_INCLINATION_RELATIVE_TO_HOURS_SHIFTED.west.seekFromHour * 60));
    } else if (m >= 3 * 60) {
      avoidFromMinute = Clock.shiftClockMinutes(m + (this.LIGHT_INCLINATION_RELATIVE_TO_HOURS_SHIFTED.east.avoidFromHour * 60));
      seekFromMinute = Clock.shiftClockMinutes(m + (this.LIGHT_INCLINATION_RELATIVE_TO_HOURS_SHIFTED.east.seekFromHour * 60));
    } else {
      return undefined;
    }

    let effectOfDirection: DirectionalJetlag;

    if (mOnReturn === m) {
      effectOfDirection = DirectionalJetlag.EQUALLY_HARD;
    } else if (Math.abs(mOnReturn) === Math.abs(m)) {
      // Jet lag after flights to the west is generally felt to be less pronounced
      effectOfDirection = m < 0 ? DirectionalJetlag.HARD_COMING_BACK : DirectionalJetlag.HARD_GOING_THERE;
    } else if (Math.sign(mOnReturn) === Math.sign(m)) {
      effectOfDirection = Math.abs(m) > Math.abs(mOnReturn) ? DirectionalJetlag.HARD_GOING_THERE : DirectionalJetlag.HARD_COMING_BACK;
    } else {
      console.warn({ minutesCrossed, m, mOnReturn });
      throw new Error("Can't compare jetlag direction difficulty for opposite, unequal leaps");
    }

    const preparatoryBedtimeShift = this.recommendBedtimeShift(minutesCrossed);

    if (!preparatoryBedtimeShift) {
      throw new Error('Could not calculate bedtime shift');
    }

    return {
      effectOfDirection,

      avoid: {
        fromMinute: avoidFromMinute,
        toMinute: Clock.shiftClockMinutes(avoidFromMinute + (windowHours * 60)),
      },
      seek: {
        fromMinute: seekFromMinute,
        toMinute: Clock.shiftClockMinutes(seekFromMinute + (windowHours * 60)),
      },

      preparatoryBedtimeShift,
    };
  }

  public static getJetlagTips(minutesOffset: number): JetlagInfo | undefined {
    const recommendation = this.recommendLightExposureTimes(minutesOffset);

    if (!recommendation) {
      return undefined;
    }

    const {
      seek, avoid, effectOfDirection, preparatoryBedtimeShift,
    } = recommendation;

    return {
      light: {
        [InclinationTowardsLight.SEEK]: this.verbalizeLightRecommendation(seek.fromMinute, InclinationTowardsLight.SEEK),
        [InclinationTowardsLight.AVOID]: this.verbalizeLightRecommendation(avoid.fromMinute, InclinationTowardsLight.AVOID),
      },
      effectOfDirection,
      preparatoryBedtimeShift,
    };
  }

  private static hourOfDayInfo(minutes: number) {
    const h = Math.floor(Clock.shiftClock(minutes / 60)) as HourOfDay;

    return ColloquialHoursOfDay[h];
  }

  private static verbalizeLightRecommendation(minuteOfDay: number, inclination: InclinationTowardsLight): LightRecommendation {
    const tips: (LightRecommendation & {
      outOfOrdinaryTime: ColloquialTimeOfDay;
    })[] = [];

    const baseWindow = {
      fromMinute: Clock.shiftClockMinutes(minuteOfDay),
      toMinute: Clock.shiftClockMinutes(minuteOfDay + (this.RECOMMENDATION_WINDOW_HOURS * 60)),
    };

    for (let h = 0; h < this.RECOMMENDATION_WINDOW_HOURS; h += 1) {
      const mins = minuteOfDay + (h * 60);
      const hourOfDay = this.hourOfDayInfo(mins);

      if (hourOfDay.light !== inclination) {
        // Out of ordinary recommendation
        tips.push({
          outOfOrdinaryTime: hourOfDay.colloquial,
          ...baseWindow,
        });
      }
    }

    if (tips.length < 1) {
      return baseWindow;
    }

    const first = tips[0];
    const last = tips[tips.length - 1];

    if (first.outOfOrdinaryTime.timeOfDay !== last.outOfOrdinaryTime.timeOfDay) {
      throw new Error('Time window spans several times of day');
    }

    if (tips.length < 2 || first.outOfOrdinaryTime.timeOfDayPrefix === last.outOfOrdinaryTime.timeOfDayPrefix) {
      return first;
    }

    return {
      ...first,
      outOfOrdinaryTime: {
        ...first.outOfOrdinaryTime,
        timeOfDayPrefix: undefined, // first and last prefix are different, so don't specify
      },
    };
  }

  public static areEqualInclinations(info1: LightRecommendation, info2: LightRecommendation) {
    const a = info1?.outOfOrdinaryTime;
    const b = info2?.outOfOrdinaryTime;

    if (!notEmpty(a) && !notEmpty(b)) {
      return true;
    }
    if (!notEmpty(a) || !notEmpty(b)) {
      return false;
    }
    return a.timeOfDay === b.timeOfDay && a.timeOfDayPrefix === b.timeOfDayPrefix;
  }

  public static areColloquiallyTheSame(info1: LightInfo, info2: LightInfo): boolean {
    return this.areEqualInclinations(info1.avoid, info2.avoid) && this.areEqualInclinations(info1.seek, info2.seek);
  }

  public static aggregateBedtimeShift(zones: RelativeTimezone[]): BedtimeShift | undefined {
    const firstOne = zones.find((tz) => typeof tz?.jetlag?.preparatoryBedtimeShift !== 'undefined');

    if (!firstOne || !firstOne.jetlag) {
      return undefined;
    }

    const { preparatoryBedtimeShift: bedtimeShift } = firstOne.jetlag;

    // Not every tz has to have jetlag tips, but none of the bedtime recommendations can conflict with the one(s) that do(es)
    const allMatch = zones.every((tz) => CircadianClock.recommendBedtimeShift(tz.minutesOffset) === bedtimeShift);

    return allMatch ? bedtimeShift : undefined;
  }
}
