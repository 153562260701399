
























import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'Title',
});
