import { RawLocation, Route, RouteConfig } from 'vue-router';
import { CountryRoute } from '@/router/CountryRoute';
import { HomeCountry } from '@/modules/HomeCountry';
import Home from '../views/Home.vue';
import { CountryCode, CountryCodes, randomCountry } from '../types/CountryCode';

// RegEx to match only the recognized country codes
const countryCodes: string = CountryCodes.join('|');

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/faq',
    name: 'faq',

    // route level code-splitting
    // this generates a separate chunk ([].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/FAQ.vue'),
  },
  {
    path: '/random',
    name: 'random',
    redirect: ({ params }) => {
      let country: CountryCode;
      do {
        country = randomCountry();
      } while (country === HomeCountry.getStoredCountryCode());
      // Loophole here: User has no home country stored, is sent to their own country (0.5% chance) right before origin detection

      return {
        name: CountryRoute.createRouterLink(country).name,
        params: {
          ...params,
          destination: country,
        },
      };
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/PageNotFound.vue'),
  },

  ...CountryCodes.flatMap((code) => [{

    path: `${CountryRoute.from(code).getPathMatcher()}`,
    name: CountryRoute.createRouterLink(code).name,

    component: () => import('../views/Destination.vue'),

    props: (route: Route) => ({
      originOverride: CountryRoute.getOriginFromHash(route.hash),
    }),
  },
  {
    // Fallback for if the legible country name changes between versions
    // As this is pushed after the previous route, it matches with lower priority
    path: `${CountryRoute.from(code).getPathMatcher('*')}`,
    redirect(config: Route): RawLocation {
      const { params } = config;
      const loc: RawLocation = {
        name: CountryRoute.createRouterLink(params.destination as CountryCode).name,
        params,
      };
      return loc;
    },
  },
  {
    path: `/:origin(${countryCodes})?${CountryRoute.from(code).getPathMatcher('*')}`,
    redirect: ({ params }: Route) => {
      console.error(new Error('Used deprecated route'));
      return CountryRoute.createRouterLink(params.destination as CountryCode, params.origin as CountryCode);
    },
  },
  {
    path: `/:origin(${countryCodes})?/${code}`,
    name: code,
    redirect: ({ params }: Route): RawLocation => {
      console.error(new Error('Used deprecated shorthand route'));
      return {
        name: CountryRoute.createRouterLink(code).name,
        params: {
          ...params,
          destination: code,
        },
      };
    },
  }]),
];
