export enum CardType {
  ELEC = 'elec',
  CASH = 'cash',
  TIME = 'time',
  TIPS = 'tips',
  AQUA = 'aqua',
}

export const DefaultCardOrder = [
  CardType.CASH,
  CardType.ELEC,
  CardType.AQUA,
  CardType.TIME,
  CardType.TIPS,
];
