import Vue from 'vue';
import VueRouter, { RawLocation, RouteConfig } from 'vue-router';
import { Platform } from '@/modules/Platform';
import NProgress from 'nprogress';
import { routes } from './routes';

Vue.use(VueRouter);

const mode: {mode?: 'history'} = {};

if (!Platform.isMobile) {
  mode.mode = 'history';
}

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  ...mode,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  NProgress.done();
});

export default router;
