/**
 * Clock utilities, nothing specific to this app
 */
export class Clock {
  public static mod = (n: number, m: number) => ((n % m) + m) % m;

  /**
   * Clock arithmetic to re-map amount of hours to a desired 24-hour period.
   */
  public static shiftClock(hour: number, minHour = 0) {
    return this.mod(hour - minHour, 24) + minHour;
  }

  /**
   * Clock arithmetic to re-map amount of minutes to a desired 24-hour period.
   */
  public static shiftClockMinutes(minute: number, minHour = 0) {
    return Math.round(this.shiftClock(minute / 60, minHour) * 60);
  }
}
