













import Vue, { PropType } from 'vue';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import { CountryNames } from '@/types/CountryNames';
import { NameOfCountry } from '@/modules/NameOfCountry';
import { SocketType } from '@/types/elec/SocketType';

export default Vue.extend({
  name: 'CountrySelect',

  computed: {
    items: () => CountryCodes
      .filter((code) => typeof CountryNames[code] !== 'undefined')
      .map((code) => ({
        value: code,
        text: NameOfCountry.get(code) as string,
      })),
  },

  props: {
    label: {
      type: String as PropType<CountryCode>,
      required: true,
    },
    value: {
      type: String,
    },
  },

  methods: {
    updateValue(value: CountryCode) {
      this.$emit('input', value);
    },
  },
});
