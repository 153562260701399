






















import Vue, { PropType } from 'vue';
import * as onClickaway from 'vue-clickaway';
import { DebugOptions } from '@/store';

export default Vue.extend<unknown, unknown, {
  debugOptions: DebugOptions | undefined;
  shown: boolean;
  autoDetectCountry: boolean;
    }, unknown>({
      name: 'Debugger',

      computed: {
        debugOptions(): DebugOptions | undefined {
          return this.$store.state.debugOptions;
        },
        autoDetectCountry: {
          get() {
            if (!this.debugOptions) {
              return true; // Failsafe
            }
            return this.debugOptions.autoDetectCountry;
          },
          set(autoDetectCountry: boolean) {
            const debugOptions: DebugOptions = {
              ...(this.debugOptions || {}),
              autoDetectCountry,
            };
            this.$store.commit('toggleDebugging', debugOptions);
          },
        },
        shown(): boolean {
          return typeof this.debugOptions !== 'undefined';
        },
      },
    });
