import { CountryCode, CountryCodes } from '../types/CountryCode';
import { CountryNames, ColloquialCountryNames } from '../types/CountryNames';

export class NameOfCountry {
  public static get(country: CountryCode | undefined | null): string {
    if (!country) {
      return '';
    }

    const countryName = (ColloquialCountryNames[country] || CountryNames[country] || '').trim();

    const capitalizeFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

    if (countryName.endsWith(')')) {
      const parenthesisAt = countryName.lastIndexOf('(');

      if (parenthesisAt < 0) {
        return countryName; // Rogue closing parenthesis
      }

      const withoutParantheses = countryName.substring(0, parenthesisAt).trim();
      const parenthesizedPart = capitalizeFirst(
        countryName.substring(parenthesisAt + 1, countryName.length - 1).trim(),
      );

      return `${parenthesizedPart} ${withoutParantheses}`;
    }

    return countryName;
  }

  public static reverseQuery(query: string): CountryCode[] {
    const found = CountryCodes
      .filter((code) => {
        const lookupName = NameOfCountry.get(code);
        return lookupName && lookupName.toLowerCase().indexOf(query.toLowerCase()) >= 0;
      });

    return found;
  }
}
