




import Vue, { PropType } from 'vue';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import { NameOfCountry } from '@/modules/NameOfCountry';
import { SocketType } from '@/types/elec/SocketType';

export default Vue.extend({
  name: 'CountryName',

  props: {
    country: {
      type: String as PropType<CountryCode>,
      required: true,
    },
  },

  computed: {
    countryName(): string {
      return NameOfCountry.get(this.country);
    },
  },
});
