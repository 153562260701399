
























































































































































import Vue from 'vue';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import AppBar from '@/components/AppBar.vue';
import { Platform } from '@/modules/Platform';
import CountryName from '@/components/CountryName.vue';
import Title from '@/components/Title.vue';
import 'nprogress/nprogress.css';
import { NavigationGuardNext, RawLocation, Route } from 'vue-router';
import { HomeCountry, StoredHomeCountry } from '@/modules/HomeCountry';
import * as onClickaway from 'vue-clickaway';
import ExternalLink from '@/components/ExternalLink.vue';
import { CountryRoute } from '@/router/CountryRoute';
import UserPreferencesManager from '@/components/UserPreferencesManager.vue';
import { CountryCodeAvailability } from '@/store';
import { vuetifyOptions } from '@/plugins/vuetify-options';

export default Vue.extend({
  name: 'App',

  directives: {
    onClickaway: onClickaway.directive,
  },

  data() {
    return {
      drawer: false,
      devicePolling: undefined as number | undefined,
    };
  },

  created() {
    this.$once('hook:destroyed', () => {
      this.cancelDevicePolling();
    });
  },

  computed: {
    country(): CountryCode {
      return this.$store.state.home;
    },

    deviceReady(): boolean {
      return this.$store.state.deviceReady;
    },

    showLinks() {
      return !Platform.isPrerendering;
    },

    telegramLink() {
      return process.env.VUE_APP_TELEGRAM_LINK;
    },

    storedFavs(): RawLocation[] {
      const routes: RawLocation[] = this.$store.state.favourites.map(
        (fav: CountryCode) => CountryRoute.createRouterLink(fav, this.country),
      );
      return routes;
    },

    version() {
      const v = process.env.PACKAGE_VERSION;
      const e = process.env.VUE_APP_ENVIRONMENT || process.env.NODE_ENV;
      if (!v) {
        console.warn('No version information available');
        return '';
      }

      return `${Platform.type} ${v} (${e})`;
    },
  },

  components: {
    ExternalLink,
    CountryName,
    Title,
    AppBar,
    UserPreferencesManager,
  },

  methods: {
    closeDrawer() {
      // NOTE: The native close behavior didn't work in DEPLOYED Android
      if (!this.$vuetify.breakpoint.lgAndUp) {
        this.drawer = false; // Only when the navbar is in "temporary" mode
      }
    },

    cancelDevicePolling() {
      if (typeof this.devicePolling !== 'undefined') {
        window.clearInterval(this.devicePolling);
      }
    },
  },

  mounted() {
    const storedCountry: StoredHomeCountry | undefined = HomeCountry.getStoredCountry();

    this.$store.commit('setHomeCountry', storedCountry && {
      code: storedCountry.country,
      timezone: storedCountry.timezone,
      source: CountryCodeAvailability.ESTABLISHED_PREVIOUSLY,
    });

    document.addEventListener('deviceready', () => this.$store.commit('setDeviceReady'), false);

    window.setTimeout(() => {
      if (!this.deviceReady) {
        throw new Error('Device-ready event never fired');
      }
    }, 1000);
  },

  watch: {
    deviceReady: {
      immediate: true,
      async handler(newValue: boolean) {
        if (newValue) {
          this.cancelDevicePolling();
          this.$vuetify.theme.dark = await Platform.inDarkMode();

          this.devicePolling = window.setInterval(async () => {
            this.$vuetify.theme.dark = await Platform.inDarkMode();
          }, 300);
        }
      },
    },
  },

  metaInfo() {
    const meta: {name: string; content: string; media?: string}[] = [{
      name: 'theme-color',
      content: vuetifyOptions.theme.themes.light.background,
      media: '(prefers-color-scheme: light)',
    }, {
      name: 'theme-color',
      content: vuetifyOptions.theme.themes.dark.background,
      media: '(prefers-color-scheme: dark)',
    }];

    if (!Platform.isProdDeployment) {
      meta.push({
        name: 'robots',
        content: 'noindex, nofollow',
      });
    }

    return {
      title: 'Home',
      titleTemplate: '%s - Trawel.',
      meta,
    };
  },

});
