import { CountryCode } from '@/types/CountryCode';

export class FavouriteCountries {
  public static STORAGE_KEY = 'favourite-country';

  public static MAXIMUM = 10;

  private static purge() {
    localStorage.removeItem(this.STORAGE_KEY);
  }

  private static getFromStorage(): CountryCode[] {
    const result = localStorage.getItem(FavouriteCountries.STORAGE_KEY);
    if (!result) {
      return [];
    }
    try {
      return JSON.parse(result) as CountryCode[];
    } catch (e) {
      console.warn('Malformed favorites:', result);
      return [];
    }
  }

  public static get(): CountryCode[] {
    return this.getFromStorage().filter((c: CountryCode) => c in CountryCode);
  }

  public static add(country: CountryCode): void {
    const existing = this.get();

    if (existing.includes(country)) {
      return;
    }

    const all = [
      ...existing,
      country,
    ];

    if (all.length > this.MAXIMUM) {
      throw new Error('Maximum number of favourites reached');
    }

    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(all));
  }

  public static remove(country: CountryCode): void {
    const existing = this.get();
    const all = existing.filter((c: CountryCode) => c !== country);

    return localStorage.setItem(this.STORAGE_KEY, JSON.stringify(all));
  }
}
