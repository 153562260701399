






























































































import Vue, { PropType } from 'vue';
import Title from '@/components/Title.vue';

export default Vue.extend({
  name: 'Hero',
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
  },

  components: {
    Title,
  },
});
