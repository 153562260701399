export enum PlatformType {
  BROWSER = 'browser',
  ANDROID = 'android',
  IOS = 'ios',
}

export class Platform {
  static get type(): PlatformType {
    const { cordova } = window as any;

    if (typeof cordova === 'undefined') {
      console.warn('Undetected platform');
      return PlatformType.BROWSER;
    }

    const type = cordova.platformId as PlatformType;

    if (typeof type === 'undefined') {
      console.warn('Undetected platform');
      return PlatformType.BROWSER;
    }

    return type;
  }

  static async inDarkMode(): Promise<boolean> {
    const { cordova } = window as any;

    if (typeof cordova === 'undefined' || typeof cordova.plugins === 'undefined') {
      return false;
    }

    if (this.isPrerendering) {
      return false; // No dark mode for pre-render
    }

    return new Promise((resolve) => {
      cordova.plugins.ThemeDetection.isDarkModeEnabled(({ value }: {value: boolean; message: string}) => {
        resolve(value);
      });
    });
  }

  static get isMobile(): boolean {
    return this.type !== PlatformType.BROWSER;
  }

  static get isDeployed(): boolean {
    return process.env.NODE_ENV === 'production';
  }

  static get isProdDeployment(): boolean {
    return process.env.NODE_ENV === 'production' && (!process.env.VUE_APP_ENVIRONMENT || process.env.VUE_APP_ENVIRONMENT === 'production');
  }

  static get isPrerendering(): boolean {
    return !!(window as any)?.TRAWEL_PRERENDER?.isPreRendering;
  }
}
