import { ColloquialTimeOfDay } from '@/types/time/ColloquialTimeOfDay';
import { TimeOfDay } from '@/types/time/TimeOfDay';
import { TimeOfDaySpecifier } from '@/types/time/TimeOfDaySpecifier';
import { InclinationTowardsLight } from '@/types/time/InclinationTowardsLight';

export type HourOfDay = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23;

export const ColloquialHoursOfDay: {[key in HourOfDay]: { light: InclinationTowardsLight; colloquial: ColloquialTimeOfDay}} = {
  0: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.NIGHT },
  },
  1: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.NIGHT },
  },
  2: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.NIGHT },
  },
  3: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.NIGHT },
  },
  4: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.NIGHT },
  },
  5: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.NIGHT },
  },
  6: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.MORNING, timeOfDayPrefix: TimeOfDaySpecifier.EARLY },
  },
  7: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.MORNING, timeOfDayPrefix: TimeOfDaySpecifier.EARLY },
  },
  8: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.MORNING },
  },
  9: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.MORNING },
  },
  10: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.MORNING, timeOfDayPrefix: TimeOfDaySpecifier.LATE },
  },
  11: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.MORNING, timeOfDayPrefix: TimeOfDaySpecifier.LATE },
  },
  12: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.AFTERNOON, timeOfDayPrefix: TimeOfDaySpecifier.EARLY },
  },
  13: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.AFTERNOON, timeOfDayPrefix: TimeOfDaySpecifier.EARLY },
  },
  14: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.AFTERNOON },
  },
  15: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.AFTERNOON },
  },
  16: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.AFTERNOON, timeOfDayPrefix: TimeOfDaySpecifier.LATE },
  },
  17: {
    light: InclinationTowardsLight.SEEK,
    colloquial: { timeOfDay: TimeOfDay.AFTERNOON, timeOfDayPrefix: TimeOfDaySpecifier.LATE },
  },
  18: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.EVENING, timeOfDayPrefix: TimeOfDaySpecifier.EARLY },
  },
  19: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.EVENING, timeOfDayPrefix: TimeOfDaySpecifier.EARLY },
  },
  20: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.EVENING },
  },
  21: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.EVENING },
  },
  22: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.EVENING, timeOfDayPrefix: TimeOfDaySpecifier.LATE },
  },
  23: {
    light: InclinationTowardsLight.AVOID,
    colloquial: { timeOfDay: TimeOfDay.EVENING, timeOfDayPrefix: TimeOfDaySpecifier.LATE },
  },

};
