






















































































































































import Vue from 'vue';
import { CountryCode } from '@/types/CountryCode';
import { NameOfCountry } from '@/modules/NameOfCountry';
import { HomeCountry, StoredHomeCountry } from '@/modules/HomeCountry';
import { CountryCodeAvailability } from '@/store';
import CountryName from '@/components/CountryName.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import CountrySelect from '@/components/CountrySelect.vue';
import { mapState } from 'vuex';
import { Jetlag } from '@/modules/time/Jetlag';
import Debugger from '@/components/Debugger.vue';

export default Vue.extend({
  name: 'UserPreferencesManager',

  methods: {

    applyDetectedCountry() {
      const { detectedCountry } = this;
      if (!detectedCountry) {
        throw new Error('Cannot apply non-existent detected country');
      }

      this.countryToSet = detectedCountry.country;

      if (detectedCountry.timezone) {
        this.timezoneToSet = detectedCountry.timezone;
      }
    },

    cancelCacheRefresh() {
      if (typeof this.detectionRefreshTimer !== 'undefined') {
        window.clearTimeout(this.detectionRefreshTimer);
      }
    },

    async autodetect() {
      const detection = await HomeCountry.autodetect();

      this.$store.commit('setDetectedHome', detection);

      if (detection?.expires) {
        this.detectionRefreshTimer = window.setTimeout(this.autodetect, Number(detection.expires) - Date.now());
      } else if (!detection) {
        // Detection errored out for some reason despite exponential backoff / historical fallback, try again later
        this.detectionRefreshTimer = window.setTimeout(this.autodetect, HomeCountry.RETRY_INTERVAL);
      }
    },

    dialogToggled(isOpen: boolean) {
      if (!isOpen) {
        this.cancelHomeCountry();
      } else {
        this.toggleDialog(true);
      }
    },

    toggleDialog(isOpen: boolean) {
      this.$store.commit('toggleHomeCountryDialog', isOpen);
    },

    commitHomeCountry() {
      this.$store.commit('setHomeCountry', {
        code: this.countryToSet,
        timezone: this.timezoneToSet,
        source: CountryCodeAvailability.ESTABLISHED_PREVIOUSLY,
      });
      this.toggleDialog(false);
    },
    cancelHomeCountry() {
      this.countryToSet = this.country;
      this.timezoneToSet = this.storedTimezone;
      this.toggleDialog(false);
    },
  },

  created() {
    // Make back button close the dialog
    const unregisterRouterGuard = this.$router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
      if (this.selectingHomeCountry) {
        this.cancelHomeCountry();
        next(false);
      } else {
        next();
      }
    });

    this.$once('hook:destroyed', () => {
      // This will be called when the component is destroyed.
      unregisterRouterGuard();
      this.cancelCacheRefresh();
    });
  },

  mounted() {
    this.autodetect();
  },

  components: {
    CountryName,
    CountrySelect,
    Debugger,
  },

  computed: {
    detectedCountry(): StoredHomeCountry | undefined {
      return this.$store.state.detectedHome;
    },

    CountryCodeAvailability() {
      return CountryCodeAvailability;
    },

    haveHome(): CountryCodeAvailability {
      return this.$store.state.haveHome;
    },

    country(): CountryCode {
      return this.$store.state.home;
    },
    storedTimezone(): CountryCode {
      return this.$store.state.homeTimezone;
    },

    ...mapState(['selectingHomeCountry']),
  },

  data() {
    return {
      snackbar: false,
      snackbarMessage: undefined as string | undefined,
      countryToSet: undefined as CountryCode | undefined,
      timezones: [] as string[],
      timezoneToSet: undefined as string | undefined,
      loading: false,
      error: '',
      detectionRefreshTimer: undefined as number | undefined,
    };
  },

  watch: {
    country: {
      immediate: true,
      async handler(newValue: CountryCode) {
        this.countryToSet = newValue;
      },
    },
    storedTimezone: {
      immediate: true,
      async handler(newValue: string) {
        if (!this.timezoneToSet) {
          this.timezoneToSet = newValue;
        }
      },
    },
    haveHome: {
      immediate: true,
      handler(newValue: CountryCodeAvailability) {
        if (newValue === CountryCodeAvailability.DEDUCED_FROM_IP || newValue === CountryCodeAvailability.DEDUCED_FROM_URL) {
          this.snackbar = true;
          this.snackbarMessage = newValue === CountryCodeAvailability.DEDUCED_FROM_URL
            ? `${NameOfCountry.get(this.country)} has been set as your home country.`
            : `${NameOfCountry.get(this.country)} has been detected as your home country.`;

          // Don't trigger this again:
          this.$store.commit('setHomeCountry', {
            code: this.country,
            timezone: this.storedTimezone,
            source: CountryCodeAvailability.ESTABLISHED_PREVIOUSLY,
          });
        }
      },
    },
    countryToSet: {
      immediate: true,
      async handler(newValue: CountryCode, oldValue?: CountryCode) {
        if (!newValue && !oldValue) {
          return;
        }

        this.timezones = [];
        this.error = '';

        if (newValue) {
          this.loading = true;

          try {
            const timezones = (await Jetlag.getAllTimezones(newValue)).map(({ name }) => name);

            if (!this.timezoneToSet || !timezones.includes(this.timezoneToSet)) {
              // Current timezoneToSet cannot be applied

              if (timezones.length === 1) {
                this.timezoneToSet = timezones[0];
              } else if (timezones.length > 1) {
                this.timezoneToSet = await Jetlag.detectedDeviceTimezoneInCountry(newValue).then((tz) => tz?.name).catch(undefined);
              } else {
                this.timezoneToSet = undefined;
              }
            }

            this.timezones = timezones;
          } catch (e) {
            this.error = `Couldn't find suitable time zones for ${NameOfCountry.get(newValue)}`;
            console.warn(`Couldn't load time zones for ${newValue}`, e);
            this.timezoneToSet = undefined;
          }

          this.loading = false;
        } else {
          this.timezoneToSet = undefined;
        }
      },
    },
  },
});
