export default {
  isDateString: (dateString: unknown): dateString is string => {
    if (typeof dateString !== 'string') {
      return false;
    }

    const d = new Date(dateString);
    return !Number.isNaN(d.getTime());
  },

  isInFuture(date: Date): boolean {
    return date.getTime() > Date.now();
  },
};
