export enum CardPriority {
  // Ordered by severity:
  OK,
  WARNING,
  ISSUE,
}
export const CardColor: {[key in CardPriority]: string} = {
  [CardPriority.OK]: 'trawel-muted-card',
  [CardPriority.WARNING]: 'trawel-warning-card',
  [CardPriority.ISSUE]: 'trawel-error-card',
};
