import { CountryCode } from './CountryCode';

export const CountryNames: {[code in CountryCode]?: string} = {
  [CountryCode.af]: 'Afghanistan',
  [CountryCode.al]: 'Albania',
  [CountryCode.dz]: 'Algeria',
  [CountryCode.as]: 'American Samoa',
  [CountryCode.ad]: 'Andorra',
  [CountryCode.ao]: 'Angola',
  [CountryCode.ai]: 'Anguilla',
  [CountryCode.aq]: 'Antarctica',
  [CountryCode.ag]: 'Antigua and Barbuda',
  [CountryCode.ar]: 'Argentina',
  [CountryCode.am]: 'Armenia',
  [CountryCode.aw]: 'Aruba',
  [CountryCode.au]: 'Australia',
  [CountryCode.at]: 'Austria',
  [CountryCode.az]: 'Azerbaijan',
  [CountryCode.bs]: 'Bahamas (the)',
  [CountryCode.bh]: 'Bahrain',
  [CountryCode.bd]: 'Bangladesh',
  [CountryCode.bb]: 'Barbados',
  [CountryCode.by]: 'Belarus',
  [CountryCode.be]: 'Belgium',
  [CountryCode.bz]: 'Belize',
  [CountryCode.bj]: 'Benin',
  [CountryCode.bm]: 'Bermuda',
  [CountryCode.bt]: 'Bhutan',
  [CountryCode.bo]: 'Bolivia (Plurinational State of)',
  [CountryCode.bq]: 'Bonaire, Sint Eustatius and Saba',
  [CountryCode.ba]: 'Bosnia and Herzegovina',
  [CountryCode.bw]: 'Botswana',
  [CountryCode.bv]: 'Bouvet Island',
  [CountryCode.br]: 'Brazil',
  [CountryCode.io]: 'British Indian Ocean Territory (the)',
  [CountryCode.bn]: 'Brunei Darussalam',
  [CountryCode.bg]: 'Bulgaria',
  [CountryCode.bf]: 'Burkina Faso',
  [CountryCode.bi]: 'Burundi',
  [CountryCode.cv]: 'Cabo Verde',
  [CountryCode.kh]: 'Cambodia',
  [CountryCode.cm]: 'Cameroon',
  [CountryCode.ca]: 'Canada',
  [CountryCode.ky]: 'Cayman Islands (the)',
  [CountryCode.cf]: 'Central African Republic (the)',
  [CountryCode.td]: 'Chad',
  [CountryCode.cl]: 'Chile',
  [CountryCode.cn]: 'China',
  [CountryCode.cx]: 'Christmas Island',
  [CountryCode.cc]: 'Cocos (Keeling) Islands (the)',
  [CountryCode.co]: 'Colombia',
  [CountryCode.km]: 'Comoros (the)',
  [CountryCode.cd]: 'Congo (the Democratic Republic of the)',
  [CountryCode.cg]: 'Congo (the)',
  [CountryCode.ck]: 'Cook Islands (the)',
  [CountryCode.cr]: 'Costa Rica',
  [CountryCode.hr]: 'Croatia',
  [CountryCode.cu]: 'Cuba',
  [CountryCode.cw]: 'Curaçao',
  [CountryCode.cy]: 'Cyprus',
  [CountryCode.cz]: 'Czechia',
  [CountryCode.ci]: 'Côte d\'Ivoire',
  [CountryCode.dk]: 'Denmark',
  [CountryCode.dj]: 'Djibouti',
  [CountryCode.dm]: 'Dominica',
  [CountryCode.do]: 'Dominican Republic (the)',
  [CountryCode.ec]: 'Ecuador',
  [CountryCode.eg]: 'Egypt',
  [CountryCode.sv]: 'El Salvador',
  [CountryCode.gq]: 'Equatorial Guinea',
  [CountryCode.er]: 'Eritrea',
  [CountryCode.ee]: 'Estonia',
  [CountryCode.sz]: 'Eswatini',
  [CountryCode.et]: 'Ethiopia',
  [CountryCode.fk]: 'Falkland Islands (the)',
  [CountryCode.fo]: 'Faroe Islands (the)',
  [CountryCode.fj]: 'Fiji',
  [CountryCode.fi]: 'Finland',
  [CountryCode.fr]: 'France',
  [CountryCode.gf]: 'French Guiana',
  [CountryCode.pf]: 'French Polynesia',
  [CountryCode.tf]: 'French Southern Territories (the)',
  [CountryCode.ga]: 'Gabon',
  [CountryCode.gm]: 'Gambia (the)',
  [CountryCode.ge]: 'Georgia',
  [CountryCode.de]: 'Germany',
  [CountryCode.gh]: 'Ghana',
  [CountryCode.gi]: 'Gibraltar',
  [CountryCode.gr]: 'Greece',
  [CountryCode.gl]: 'Greenland',
  [CountryCode.gd]: 'Grenada',
  [CountryCode.gp]: 'Guadeloupe',
  [CountryCode.gu]: 'Guam',
  [CountryCode.gt]: 'Guatemala',
  [CountryCode.gg]: 'Guernsey',
  [CountryCode.gn]: 'Guinea',
  [CountryCode.gw]: 'Guinea-Bissau',
  [CountryCode.gy]: 'Guyana',
  [CountryCode.ht]: 'Haiti',
  [CountryCode.hm]: 'Heard Island and McDonald Islands',
  [CountryCode.va]: 'Holy See (the)',
  [CountryCode.hn]: 'Honduras',
  [CountryCode.hk]: 'Hong Kong',
  [CountryCode.hu]: 'Hungary',
  [CountryCode.is]: 'Iceland',
  [CountryCode.in]: 'India',
  [CountryCode.id]: 'Indonesia',
  [CountryCode.ir]: 'Iran',
  [CountryCode.iq]: 'Iraq',
  [CountryCode.ie]: 'Ireland',
  [CountryCode.im]: 'Isle of Man',
  [CountryCode.il]: 'Israel',
  [CountryCode.it]: 'Italy',
  [CountryCode.jm]: 'Jamaica',
  [CountryCode.jp]: 'Japan',
  [CountryCode.je]: 'Jersey',
  [CountryCode.jo]: 'Jordan',
  [CountryCode.kz]: 'Kazakhstan',
  [CountryCode.ke]: 'Kenya',
  [CountryCode.ki]: 'Kiribati',
  [CountryCode.kp]: 'Korea (the Democratic People\'s Republic of)',
  [CountryCode.kr]: 'Korea',
  [CountryCode.kw]: 'Kuwait',
  [CountryCode.kg]: 'Kyrgyzstan',
  [CountryCode.la]: 'Lao People\'s Democratic Republic (the)',
  [CountryCode.lv]: 'Latvia',
  [CountryCode.lb]: 'Lebanon',
  [CountryCode.ls]: 'Lesotho',
  [CountryCode.lr]: 'Liberia',
  [CountryCode.ly]: 'Libya',
  [CountryCode.li]: 'Liechtenstein',
  [CountryCode.lt]: 'Lithuania',
  [CountryCode.lu]: 'Luxembourg',
  [CountryCode.mo]: 'Macao',
  [CountryCode.mg]: 'Madagascar',
  [CountryCode.mw]: 'Malawi',
  [CountryCode.my]: 'Malaysia',
  [CountryCode.mv]: 'Maldives',
  [CountryCode.ml]: 'Mali',
  [CountryCode.mt]: 'Malta',
  [CountryCode.mh]: 'Marshall Islands (the)',
  [CountryCode.mq]: 'Martinique',
  [CountryCode.mr]: 'Mauritania',
  [CountryCode.mu]: 'Mauritius',
  [CountryCode.yt]: 'Mayotte',
  [CountryCode.mx]: 'Mexico',
  [CountryCode.fm]: 'Micronesia (Federated States of)',
  [CountryCode.md]: 'Moldova',
  [CountryCode.mc]: 'Monaco',
  [CountryCode.mn]: 'Mongolia',
  [CountryCode.me]: 'Montenegro',
  [CountryCode.ms]: 'Montserrat',
  [CountryCode.ma]: 'Morocco',
  [CountryCode.mz]: 'Mozambique',
  [CountryCode.mm]: 'Myanmar',
  [CountryCode.na]: 'Namibia',
  [CountryCode.nr]: 'Nauru',
  [CountryCode.np]: 'Nepal',
  [CountryCode.nl]: 'Netherlands (the)',
  [CountryCode.nc]: 'New Caledonia',
  [CountryCode.nz]: 'New Zealand',
  [CountryCode.ni]: 'Nicaragua',
  [CountryCode.ne]: 'Niger (the)',
  [CountryCode.ng]: 'Nigeria',
  [CountryCode.nu]: 'Niue',
  [CountryCode.nf]: 'Norfolk Island',
  [CountryCode.mp]: 'Northern Mariana Islands (the)',
  [CountryCode.no]: 'Norway',
  [CountryCode.om]: 'Oman',
  [CountryCode.pk]: 'Pakistan',
  [CountryCode.pw]: 'Palau',
  [CountryCode.ps]: 'Palestine (State of)',
  [CountryCode.pa]: 'Panama',
  [CountryCode.pg]: 'Papua New Guinea',
  [CountryCode.py]: 'Paraguay',
  [CountryCode.pe]: 'Peru',
  [CountryCode.ph]: 'Philippines (the)',
  [CountryCode.pn]: 'Pitcairn',
  [CountryCode.pl]: 'Poland',
  [CountryCode.pt]: 'Portugal',
  [CountryCode.pr]: 'Puerto Rico',
  [CountryCode.qa]: 'Qatar',
  [CountryCode.mk]: 'Republic of North Macedonia',
  [CountryCode.ro]: 'Romania',
  [CountryCode.ru]: 'Russian Federation (the)',
  [CountryCode.rw]: 'Rwanda',
  [CountryCode.re]: 'Réunion',
  [CountryCode.bl]: 'Saint Barthélemy',
  [CountryCode.sh]: 'Saint Helena, Ascension and Tristan da Cunha',
  [CountryCode.kn]: 'Saint Kitts and Nevis',
  [CountryCode.lc]: 'Saint Lucia',
  [CountryCode.mf]: 'Saint Martin, French part',
  [CountryCode.pm]: 'Saint Pierre and Miquelon',
  [CountryCode.vc]: 'Saint Vincent and the Grenadines',
  [CountryCode.ws]: 'Samoa',
  [CountryCode.sm]: 'San Marino',
  [CountryCode.st]: 'Sao Tome and Principe',
  [CountryCode.sa]: 'Saudi Arabia',
  [CountryCode.sn]: 'Senegal',
  [CountryCode.rs]: 'Serbia',
  [CountryCode.sc]: 'Seychelles',
  [CountryCode.sl]: 'Sierra Leone',
  [CountryCode.sg]: 'Singapore',
  [CountryCode.sx]: 'Sint Maarten',
  [CountryCode.sk]: 'Slovakia',
  [CountryCode.si]: 'Slovenia',
  [CountryCode.sb]: 'Solomon Islands',
  [CountryCode.so]: 'Somalia',
  [CountryCode.za]: 'South Africa',
  [CountryCode.gs]: 'South Georgia and the South Sandwich Islands',
  [CountryCode.ss]: 'South Sudan',
  [CountryCode.es]: 'Spain',
  [CountryCode.lk]: 'Sri Lanka',
  [CountryCode.sd]: 'Sudan (the)',
  [CountryCode.sr]: 'Suriname',
  [CountryCode.sj]: 'Svalbard and Jan Mayen',
  [CountryCode.se]: 'Sweden',
  [CountryCode.ch]: 'Switzerland',
  [CountryCode.sy]: 'Syrian Arab Republic',
  [CountryCode.tw]: 'Taiwan, Province of China',
  [CountryCode.tj]: 'Tajikistan',
  [CountryCode.tz]: 'Tanzania',
  [CountryCode.th]: 'Thailand',
  [CountryCode.tl]: 'Timor-Leste',
  [CountryCode.tg]: 'Togo',
  [CountryCode.tk]: 'Tokelau',
  [CountryCode.to]: 'Tonga',
  [CountryCode.tt]: 'Trinidad and Tobago',
  [CountryCode.tn]: 'Tunisia',
  [CountryCode.tr]: 'Turkey',
  [CountryCode.tm]: 'Turkmenistan',
  [CountryCode.tc]: 'Turks and Caicos Islands (the)',
  [CountryCode.tv]: 'Tuvalu',
  [CountryCode.ug]: 'Uganda',
  [CountryCode.ua]: 'Ukraine',
  [CountryCode.ae]: 'United Arab Emirates (the)',
  [CountryCode.gb]: 'United Kingdom of Great Britain and Northern Ireland (the)',
  [CountryCode.um]: 'United States Minor Outlying Islands (the)',
  [CountryCode.us]: 'United States of America (the)',
  [CountryCode.uy]: 'Uruguay',
  [CountryCode.uz]: 'Uzbekistan',
  [CountryCode.vu]: 'Vanuatu',
  [CountryCode.ve]: 'Venezuela',
  [CountryCode.vn]: 'Viet Nam',
  [CountryCode.vg]: 'Virgin Islands (British)',
  [CountryCode.vi]: 'Virgin Islands (U.S.)',
  [CountryCode.wf]: 'Wallis and Futuna',
  [CountryCode.eh]: 'Western Sahara',
  [CountryCode.ye]: 'Yemen',
  [CountryCode.zm]: 'Zambia',
  [CountryCode.zw]: 'Zimbabwe',
  [CountryCode.ax]: 'Åland Islands',
  [CountryCode.xk]: 'Kosovo',
  [CountryCode.st]: 'São Tomé and Príncipe',
};

export const ColloquialCountryNames: {[code in CountryCode]?: string} = {
  [CountryCode.jp]: 'Japan',
  [CountryCode.us]: 'The United States',
  [CountryCode.in]: 'India',
  [CountryCode.nz]: 'New Zealand',
  [CountryCode.eg]: 'Egypt',
  [CountryCode.gb]: 'The United Kingdom',
  [CountryCode.kp]: 'North Korea',
  [CountryCode.is]: 'Iceland',
  [CountryCode.ru]: 'Russia',
  [CountryCode.la]: 'Laos',
  [CountryCode.vn]: 'Vietnam',
};
