


















































import Vue from 'vue';
import Hero from '@/components/Hero.vue';
import { CountryCode } from '@/types/CountryCode';
import { Platform } from '@/modules/Platform';
import mixins from 'vue-typed-mixins';
import { ViewMixin } from '@/mixins/ViewMixin';
import ExternalLink from '@/components/ExternalLink.vue';

export default mixins(ViewMixin).extend({
  name: 'Home',

  components: {
    ExternalLink,
    Hero,
  },

  computed: {
    country(): CountryCode {
      return this.$store.state.home;
    },
    tagline() {
      return 'Select your vacation spot for crucial travel information.';
    },
    isApp(): boolean {
      return Platform.isMobile;
    },
  },

  methods: {
    openDestinationCountryDialog() {
      this.$store.commit('toggleDestinationCountryDialog', true);
    },
  },

  metaInfo: {
    title: 'Select your vacation spot',

    meta: [{
      vmid: 'description',
      name: 'description',
      // eslint-disable-next-line max-len
      content: 'Select your vacation spot for local tipping culture, currency information, electrical compatibility, water safety, and the time difference',
    }],
  },
});
