












import Vue, { PropType } from 'vue';
import * as onClickaway from 'vue-clickaway';

export default Vue.extend({
  name: 'ExternalLink',

  props: {
    href: {
      type: String as PropType<string>,
      required: true,
    },
    undressed: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
  },

  methods: {
    clicked(event: MouseEvent) {
      const inAppBrowserPlugin = (window as any)?.cordova?.InAppBrowser;

      if (inAppBrowserPlugin) {
        event.preventDefault();
        inAppBrowserPlugin.open(this.href, '_system');
      }
    },
  },

  computed: {
  },
});
