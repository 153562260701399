import { CountryCodes, CountryCode, isCountryCode } from '@/types/CountryCode';
import { Jetlag } from '@/modules/time/Jetlag';
import Home from '@/views/Home.vue';
import { Timezone } from 'countries-and-timezones';
import { NameOfCountry } from '@/modules/NameOfCountry';
// eslint-disable-next-line import/no-cycle
import { getDebugOptions } from '@/store/getDebugOptions';
import { Platform } from '@/modules/Platform';
import CountryName from '@/components/CountryName.vue';
import { APIClientTools } from '@/modules/APIClientTools';
import axios from 'axios';
import DateTools from '@/modules/DateTools';

export interface StoredHomeCountry {
  country: CountryCode;
  timezone?: string;
}

interface CountryDetection {
  country: CountryCode;
  expires: Date;
}

export class HomeCountry {
  private static HOME_COUNTRY_STORAGE_KEY = 'home-country';

  private static HOME_TIMEZONE_STORAGE_KEY = 'home-timezone';

  private static DETECTED_COUNTRY_STORAGE_KEY = 'detected-country';

  private static CACHE_AUTODETECTION_MINUTES = 60;

  public static get RETRY_INTERVAL() {
    return this.CACHE_AUTODETECTION_MINUTES;
  }

  private static removeCountry() {
    localStorage.removeItem(this.HOME_COUNTRY_STORAGE_KEY);
    localStorage.removeItem(this.HOME_TIMEZONE_STORAGE_KEY);
    localStorage.removeItem(this.DETECTED_COUNTRY_STORAGE_KEY);
  }

  public static getStoredCountryCode(): CountryCode | undefined {
    return this.getStoredCountry()?.country;
  }

  /**
   * Gets the stored country information.
   * Note: Will not automatically deduce timezone if it's missing.
   */
  public static getStoredCountry(): StoredHomeCountry | undefined {
    const result = localStorage.getItem(HomeCountry.HOME_COUNTRY_STORAGE_KEY) as (CountryCode | null);
    if (!result) {
      return undefined;
    }

    if (!isCountryCode(result)) {
      console.warn(`Removing unrecognized country code from storage: ${result}`);
      HomeCountry.removeCountry();
      return undefined;
    }

    const timezone = localStorage.getItem(HomeCountry.HOME_TIMEZONE_STORAGE_KEY) as (string | null) || undefined;

    return {
      country: result,
      timezone: Jetlag.isValidTimezone(timezone) ? timezone : undefined,
    };
  }

  /**
   * Only called by us and vuex
   */
  public static setCountry(country: StoredHomeCountry): void {
    if (!country?.country) {
      this.removeCountry();
      return;
    }

    if (country.timezone) {
      localStorage.setItem(this.HOME_TIMEZONE_STORAGE_KEY, country.timezone);
    } else {
      localStorage.removeItem(this.HOME_TIMEZONE_STORAGE_KEY);
    }

    localStorage.setItem(this.HOME_COUNTRY_STORAGE_KEY, country.country);
  }

  public static async attemptUserTimezoneChoice(country: CountryCode): Promise<Timezone | undefined> {
    let timezone: Timezone | undefined = await Jetlag.detectedDeviceTimezoneInCountry(country);

    if (!timezone) {
      // The user's device couldn't tell us what timezone to use. Let's look at the country's timezones.
      // If there are more than one candidate, we can't make the decision for the user
      const countryZones = await Jetlag.getUniqueTimezones(country).catch(() => []);
      if (countryZones.length === 1) {
        timezone = countryZones[0];
        // eslint-disable-next-line no-console
        console.log(`${NameOfCountry.get(country)} only has one timezone, which we will assume even though user device is not in it.`);
      } else {
        console.warn(`Couldn't assume timezone for ${NameOfCountry.get(country)}, since there are ${countryZones.length} unique options`);
      }
    }

    return timezone;
  }

  /**
   * Gets the user's autodetected home country and timezone. May come from cache.
   * Note: This is separate from the actual home country the user has selected.
   */
  public static async autodetect(): Promise<StoredHomeCountry & CountryDetection | undefined> {
    const detection = await HomeCountry.getDetectedCountry();

    if (!detection) {
      return undefined;
    }

    const timezone = await HomeCountry.attemptUserTimezoneChoice(detection.country);

    const country: StoredHomeCountry = {
      country: detection.country,
      timezone: timezone?.name,
    };

    return {
      ...country,
      ...detection,
    };
  }

  private static get shouldNotDetect(): boolean {
    return Platform.isPrerendering || getDebugOptions()?.autoDetectCountry === false;
  }

  private static get lastDetected(): CountryDetection | undefined {
    const stringPayload = localStorage.getItem(this.DETECTED_COUNTRY_STORAGE_KEY);

    if (!stringPayload) {
      return undefined;
    }

    try {
      const {
        country,
        expires: expiry,
      } = JSON.parse(stringPayload) as { country?: unknown; expires?: unknown };

      if (!isCountryCode(country)) {
        throw new Error(`Unrecognized country code '${country}'`);
      }

      if (!DateTools.isDateString(expiry)) {
        throw new Error(`Invalid date '${expiry}'`);
      }

      const expires: Date = new Date(expiry);
      const expirationMs = this.CACHE_AUTODETECTION_MINUTES * 60 * 1000;

      if (DateTools.isInFuture(new Date(expires.getTime() - expirationMs))) {
        throw new Error('Expiration too far into future');
      }

      return {
        country,
        expires,
      };
    } catch (e) {
      console.error('Illegible cached detection', e);
      return undefined;
    }
  }

  private static set lastDetected(payload: CountryDetection | undefined) {
    if (!payload) {
      localStorage.removeItem(this.DETECTED_COUNTRY_STORAGE_KEY);
    } else {
      localStorage.setItem(this.DETECTED_COUNTRY_STORAGE_KEY, JSON.stringify(payload));
    }
  }

  /**
   * Returns the country that we detected the user to be in. Might be a cached value.
   */
  private static async getDetectedCountry(): Promise<CountryDetection | undefined> {
    if (this.shouldNotDetect) {
      return undefined;
    }

    const { lastDetected } = this;

    if (lastDetected?.expires && new Date(lastDetected.expires) > new Date()) {
      return lastDetected;
    }

    try {
      await APIClientTools.withRetries(() => axios
        .get('https://www.iplocate.io/api/lookup')
        .then((response) => {
          const countryCode = response?.data?.country_code;
          const code = countryCode.toLowerCase() as CountryCode;

          // The following Errors will trigger API retries
          if (!countryCode) {
            throw new Error('No country code in response');
          }
          if (!(code in CountryCode)) {
            throw new Error(`Unrecognized country code "${code}" in response`);
          }

          this.lastDetected = { country: code, expires: new Date(Date.now() + this.CACHE_AUTODETECTION_MINUTES * 60 * 1000) };

          console.info(
            'Client location detected as',
            NameOfCountry.get(code),
            `(${code})`,
            '- caching for',
            this.CACHE_AUTODETECTION_MINUTES, 'minutes',
          );
        }));

      return this.lastDetected; // Will have been set by promise above
    } catch (e) {
      console.error('Failed to fetch client country', e);

      if (!lastDetected) {
        return undefined;
      }

      console.info(`Falling back on '${lastDetected.country}' as detected country.`);

      return {
        ...lastDetected,
        expires: new Date(Date.now() + this.CACHE_AUTODETECTION_MINUTES * 60 * 1000), // Consumer is free to try again later
      };
    }
  }
}
