import { Location } from 'vue-router';
import { NameOfCountry } from '../modules/NameOfCountry';
import { CountryCode, CountryCodes, isCountryCode } from '../types/CountryCode';

export class CountryRoute {
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly countryCode: CountryCode) {
  }

  static getOriginHash(origin: CountryCode) {
    return `#!from-${origin}`;
  }

  static createRouterLink(destination: CountryCode, origin?: CountryCode): Location {
    const params: Record<string, string> = { destination };

    if (origin) {
      params.origin = origin;
    }

    return {
      name: `${destination}-full`,
      params,
      hash: origin ? CountryRoute.getOriginHash(origin) : undefined,
    };
  }

  static getOriginFromHash(hash: string): CountryCode | undefined {
    const candidate = hash.replace('#!from-', '').trim();

    if (isCountryCode(candidate)) {
      return candidate;
    }

    return undefined;
  }

  public getCanonicalURL(origin?: CountryCode): URL {
    const originPart: string = origin ? CountryRoute.getOriginHash(origin) : '';
    return new URL(`${process.env.VUE_APP_TRAWEL_HOST}${this.getCanonicalPath(true)}${originPart}`);
  }

  public getCanonicalPath(encode = false): string {
    const country = encode ? this.getNameOfCountryEncoded() : this.getNameOfCountry();
    return `/visiting-${country}-${this.countryCode}`;
  }

  // https://stackoverflow.com/a/6969486
  private static escapeStringRegexp(s: string) {
    return s.replace(/[.*+?^${}()|[\]\\]/g, ''); // Don't trigger regexp route matching
  }

  public getPathMatcher(legiblePart = this.getNameOfCountryEncoded()) {
    return `/visiting-${legiblePart}-:destination(${this.countryCode})`;
  }

  public static from(countryCode: CountryCode) {
    return new CountryRoute(countryCode);
  }

  private getNameOfCountryEncoded() {
    const legibleCountry = encodeURIComponent(this.getNameOfCountry());
    return CountryRoute.escapeStringRegexp(legibleCountry);
  }

  private getNameOfCountry() {
    return NameOfCountry.get(this.countryCode)
      .toLowerCase()
      .replace(' ', '-')
      .replace(',', '');
  }
}
