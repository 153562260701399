import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue';
import CountryFlag from 'vue-country-flag';
import VueMeta from 'vue-meta';
import { Platform } from '@/modules/Platform';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

if (process.env.VUE_APP_SENTRY_URI) {
  const v = process.env.PACKAGE_VERSION;

  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_URI,
    release: `trawel@${v}`,
    environment: Platform.type,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1,
  });
}

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;
Vue.component('country-flag', CountryFlag);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
