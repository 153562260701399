import { Timezone } from 'countries-and-timezones';

export class OffsetUTC {
  /**
   * Method that gets the current UTC offset in minutes, taking into
   * account the current DST status.
   * https://stackoverflow.com/a/29268535/3397781
   */
  public static now(timezoneName: string) {
    const date = new Date();
    const name = timezoneName;
    // Abuse the Intl API to get a local ISO 8601 string for a given time zone.
    let iso = date.toLocaleString('en-CA', { timeZone: name, hourCycle: 'h23' });
    iso = iso.replace(', ', 'T');

    // Include the milliseconds from the original timestamp
    iso += '.';
    iso += date.getMilliseconds().toString().padStart(3, '0');

    // Lie to the Date object constructor that it's a UTC time.
    const lie = new Date(`${iso}Z`);

    // Return the difference in timestamps, as minutes
    // Positive values are West of GMT, opposite of ISO 8601
    // this matches the output of `Date.getTimeZoneOffset`
    return -(lie.getTime() - date.getTime()) / 60 / 1000;
  }
}
