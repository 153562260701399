import Vue from 'vue';
import Vuex from 'vuex';
import { CountryCode } from '@/types/CountryCode';
// eslint-disable-next-line import/no-cycle
import { HomeCountry, StoredHomeCountry } from '@/modules/HomeCountry';
import { FavouriteCountries } from '@/modules/FavouriteCountries';
import { CardType } from '@/types/CardType';
import { CardPriority } from '@/types/CardPriority';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export enum CountryCodeAvailability {
  ESTABLISHED_PREVIOUSLY, // From storage or user choice
  DEDUCED_FROM_URL,
  DEDUCED_FROM_IP,
  LOADING,
  MISSING,
}

export interface DebugOptions {
  autoDetectCountry: boolean;
}

export interface TrawelStore {
  home?: CountryCode;
  detectedHome?: StoredHomeCountry;
  homeTimezone?: string;
  haveHome: CountryCodeAvailability;
  favourites: CountryCode[];
  deviceReady: boolean;
  cardInitialized: {[card in CardType]: boolean};
  cardPriorities: {[card in CardType]: CardPriority};
  selectingHomeCountry: boolean;
  selectingDestinationCountry: boolean;
  debugOptions?: DebugOptions;
}

export default new Vuex.Store<TrawelStore>({
  state: {
    home: undefined,
    homeTimezone: undefined,
    haveHome: CountryCodeAvailability.LOADING,
    detectedHome: undefined,
    favourites: FavouriteCountries.get(),
    cardPriorities: {
      [CardType.ELEC]: CardPriority.OK,
      [CardType.CASH]: CardPriority.OK,
      [CardType.TIME]: CardPriority.OK,
      [CardType.TIPS]: CardPriority.OK,
      [CardType.AQUA]: CardPriority.OK,
    },
    cardInitialized: {
      [CardType.ELEC]: false,
      [CardType.CASH]: false,
      [CardType.TIME]: false,
      [CardType.TIPS]: false,
      [CardType.AQUA]: false,
    },
    debugOptions: undefined,
    selectingHomeCountry: false,
    selectingDestinationCountry: false,
    deviceReady: false,
  },
  mutations: {
    setHomeCountry(state, payload?: {code: CountryCode; source: CountryCodeAvailability; timezone?: string}) {
      if (typeof payload !== 'undefined') {
        state.haveHome = payload.source;
        state.home = payload.code;
        state.homeTimezone = payload.timezone || undefined;
        HomeCountry.setCountry({
          country: payload.code,
          timezone: payload.timezone,
        });
      } else {
        state.haveHome = CountryCodeAvailability.MISSING;
        state.home = undefined;
        state.homeTimezone = undefined;
      }
    },
    addFavourite(state, country: CountryCode) {
      FavouriteCountries.add(country);
      state.favourites = FavouriteCountries.get();
    },
    removeFavourite(state, country: CountryCode) {
      FavouriteCountries.remove(country);
      state.favourites = FavouriteCountries.get();
    },
    reportCardPriority(state, { card, color }: { card: CardType; color: CardPriority }) {
      state.cardPriorities[card] = color;
    },
    reportCardInitialized(state, card: CardType) {
      state.cardInitialized[card] = true;
    },
    toggleHomeCountryDialog(state, open: boolean) {
      state.selectingHomeCountry = open;
    },
    toggleDestinationCountryDialog(state, open: boolean) {
      state.selectingDestinationCountry = open;
    },
    toggleDebugging(state, debugOptions?: DebugOptions) {
      state.debugOptions = debugOptions;
    },
    setDetectedHome(state, country?: StoredHomeCountry) {
      state.detectedHome = country;
    },
    setDeviceReady(state) {
      state.deviceReady = true;
    },
  },
  strict: debug,
});
