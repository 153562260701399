import { getCountry, getTimezone, Timezone } from 'countries-and-timezones';
import TimeAgo from 'javascript-time-ago'; // load converter
import { CountryCode } from '@/types/CountryCode';
import {
  CircadianClock,

} from '@/modules/time/CircadianClock';
import { notEmpty } from '@/types/notEmpty';
import { Clock } from '@/modules/time/Clock';
import { DirectionalJetlag } from '@/types/time/DirectionalJetlag';
// eslint-disable-next-line import/extensions
import en from 'javascript-time-ago/locale/en';
import { OffsetUTC } from '@/modules/time/OffsetUTC';
import { RelativeTimezone } from '@/types/time/RelativeTimezone';
import { JetlagInfo } from '@/types/time/JetlagInfo';
import { BedtimeShift } from '@/types/time/BedtimeShift';
import { LightInfo } from '@/types/time/LightInfo';
// eslint-disable-next-line import/extensions
require('number-to-text/converters/en-us');

TimeAgo.addLocale(en);

class FaultyTimeError extends Error {
  constructor(message: string, public readonly country: CountryCode) {
    super(`${message} for country '${country}'`);
    this.name = 'FaultyTimeError';
  }
}

export enum JetlagStatusReport {
  // There is jet lag in all zone(s), and the report was able to aggregate it
  COMPLETE,

  // There is jet lag in all zones, and the reports was able to PARTIALLY aggregate it
  PARTIAL_DEPENDING_ON_ZONE,

  // There is jet lag in at least one zone, but it couldn't be aggregated
  INCOMPLETE_DEPENDING_ON_ZONE,

  // There is no jet lag in any zone (but there could still be a time difference!)
  NO_JETLAG_EXPECTED,

  // There is no time difference in any zone (and logically no jet lag either)
  EQUIVALENT_TIMEZONES
}

interface TimeDifferenceReportBase {
  deviceIsInHomeTimezone: boolean;
  origin: CountryCode;
  destination: CountryCode;
  destinationTimezonesOrdered: RelativeTimezone[]; // Guaranteed to have non-zero length
  dstDiscrepancyExists: boolean;
  preparatoryBedtimeShift?: BedtimeShift;
  jetlagInAllZones?: boolean;
}

interface TimeDifferenceReportWithoutJetlag extends TimeDifferenceReportBase {
  jetlagStatus: JetlagStatusReport.NO_JETLAG_EXPECTED | JetlagStatusReport.EQUIVALENT_TIMEZONES;
}

interface TimeDifferenceReportWithFullJetlag extends TimeDifferenceReportBase {
  jetlagStatus: JetlagStatusReport.COMPLETE;
  jetlagLightTips: LightInfo;
  jetlagEffectOfDirection: DirectionalJetlag;
}

interface TimeDifferenceReportWithIncompleteJetlag extends TimeDifferenceReportBase {
  jetlagStatus: JetlagStatusReport.INCOMPLETE_DEPENDING_ON_ZONE;
  jetlagInAllZones: boolean;
}

interface TimeDifferenceReportWithPartialJetlag extends TimeDifferenceReportBase {
  jetlagStatus: JetlagStatusReport.PARTIAL_DEPENDING_ON_ZONE;
  jetlagEffectOfDirection: DirectionalJetlag;
  jetlagInAllZones: boolean;
}

export type TimeDifferenceReport = TimeDifferenceReportWithoutJetlag |
  TimeDifferenceReportWithPartialJetlag |
  TimeDifferenceReportWithFullJetlag |
  TimeDifferenceReportWithIncompleteJetlag;

export class Jetlag {
  public readonly timezoneInfo: Promise<Timezone[]>;

  constructor(
    public readonly country: CountryCode,
  ) {
    this.timezoneInfo = Jetlag.getUniqueTimezones(country);
  }

  public static clockTurnRequiredMinutes(minutesTurned: number): number | undefined {
    if (minutesTurned === 0) {
      return undefined;
    }

    return Clock.shiftClockMinutes(minutesTurned, -12);
  }

  public static detectedDeviceTimezoneInCountry(country: CountryCode) {
    return Jetlag.countryIncludesTimezone(country, this.detectedDeviceTimezoneName);
  }

  public static get detectedDeviceTimezone(): Timezone | undefined {
    return getTimezone(Jetlag.detectedDeviceTimezoneName) || undefined;
  }

  public static get detectedDeviceTimezoneName(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public static getAllTimezones(country: CountryCode): Promise<Timezone[]> {
    return Promise.resolve(getCountry(country.toUpperCase()))

      .then((info) => {
        if (!info || !info.timezones) {
          throw new FaultyTimeError('No time data listed', country);
        }
        return info;
      })
      .then(({ timezones }) => timezones
        .map((timezoneName) => getTimezone(timezoneName))
        .filter(notEmpty));
  }

  public static async countryIncludesTimezone(country: CountryCode, timezone: string): Promise<Timezone | undefined> {
    const zones = await this.getAllTimezones(country).catch(() => undefined);

    if (!zones) {
      return undefined;
    }

    return zones.find(({ name, aliasOf }) => name === timezone || aliasOf === timezone);
  }

  public static getUniqueTimezones(country: CountryCode) {
    return this.getAllTimezones(country)

      .then((timezones) => timezones
        .filter((testedTz, i, arr) => arr.findIndex(
          (tz) => Jetlag.timezonesAreEquivalent(testedTz, tz), // Unique timezones only (eg. Germany)
        ) === i))

      .then((timezones) => {
        if (timezones.length < 1) {
          throw new FaultyTimeError('No timezone data listed', country);
        }
        return timezones;
      });
  }

  private static timezonesAreEquivalent(a: Timezone | undefined, b: Timezone | undefined) {
    if (!a || !b) {
      return false;
    }

    return a.dstOffset === b.dstOffset && a.utcOffset === b.utcOffset;
  }

  private static isComputableTimezone(timezone: Timezone | null): timezone is Timezone {
    return !(!timezone || Number.isNaN(Jetlag.getTimeOffsetNow(timezone)));
  }

  public static isValidTimezone(nameOfTimezone: string | undefined): boolean {
    if (!nameOfTimezone) {
      return false;
    }

    const timezone: Timezone | null = getTimezone(nameOfTimezone);
    return this.isComputableTimezone(timezone);
  }

  private async parseRelativeTimezones(givenOriginTimezone: Timezone) {
    const theirs: Timezone[] = await this.timezoneInfo;

    const destinationTimezonesOrdered: RelativeTimezone[] = theirs
      .filter((tz) => {
        const faulty = !Jetlag.isComputableTimezone(tz);

        if (faulty) {
          console.warn('Omitting faulty timezone', tz.name);
        }

        return !faulty;
      })
      .map((tz) => {
        const minutesOffset = Jetlag.getTimeOffsetNow(givenOriginTimezone) - Jetlag.getTimeOffsetNow(tz);
        return {
          minutesOffset,
          minutesClockTurn: Jetlag.clockTurnRequiredMinutes(minutesOffset),
          name: tz.name,
          observesDST: tz.dstOffset !== tz.utcOffset,
          jetlag: CircadianClock.getJetlagTips(minutesOffset),
        };
      })
      .sort((a, b) => a.minutesOffset - b.minutesOffset);

    if (!destinationTimezonesOrdered.length) {
      throw new Error('No destination timezones could be parsed');
    }

    return destinationTimezonesOrdered;
  }

  async get(home: CountryCode, timezone: string): Promise<TimeDifferenceReport> {
    const givenOriginTimezone: Timezone | null = getTimezone(timezone);

    if (!Jetlag.isComputableTimezone(givenOriginTimezone)) {
      throw new FaultyTimeError(`Illegible device timezone ${timezone}`, this.country);
    }

    const timezones = await this.parseRelativeTimezones(givenOriginTimezone);

    // NOTE: Even if both parties observe DST, the date the clocks change might be different (we don't know)
    const dstDiscrepancyExists: boolean = givenOriginTimezone.dstOffset !== givenOriginTimezone.utcOffset
      || typeof timezones.find(({ observesDST }) => !observesDST) !== 'undefined';

    const base: TimeDifferenceReportBase = {
      deviceIsInHomeTimezone: Jetlag.timezonesAreEquivalent(givenOriginTimezone, Jetlag.detectedDeviceTimezone),
      origin: home,
      dstDiscrepancyExists,
      destination: this.country,
      destinationTimezonesOrdered: timezones,
      preparatoryBedtimeShift: CircadianClock.aggregateBedtimeShift(timezones),
    };

    const firstTimezoneJetlag = timezones[0].jetlag;

    if (timezones.length === 1 && notEmpty(firstTimezoneJetlag)) {
      return {
        ...base,
        jetlagStatus: JetlagStatusReport.COMPLETE,
        jetlagLightTips: firstTimezoneJetlag.light,
        jetlagEffectOfDirection: firstTimezoneJetlag.effectOfDirection,
      };
    }

    if (timezones.length === 1 || timezones.every(({ jetlag }) => typeof jetlag === 'undefined')) {
      const noZoneHasTimeDifference = timezones.every(({ minutesOffset }) => minutesOffset === 0);

      return {
        ...base,
        jetlagStatus: noZoneHasTimeDifference ? JetlagStatusReport.EQUIVALENT_TIMEZONES : JetlagStatusReport.NO_JETLAG_EXPECTED,
      };
    }

    // At this point, there are multiple timezones. At least one of them has jetlag data

    const jetlagInAllZones: boolean = timezones.every(({ jetlag }) => typeof jetlag !== 'undefined');
    const allDirectionsAreTheSame = timezones.every(({ jetlag }) => jetlag?.effectOfDirection === firstTimezoneJetlag?.effectOfDirection);
    if (!firstTimezoneJetlag || !allDirectionsAreTheSame) {
      return {
        ...base,
        jetlagStatus: JetlagStatusReport.INCOMPLETE_DEPENDING_ON_ZONE,
        jetlagInAllZones,
      };
    }

    const allLightRecommendationsAreTheSame = timezones.every(
      // We know that all timezones.jetlag exist from a previous check
      ({ jetlag }) => CircadianClock.areColloquiallyTheSame((jetlag as JetlagInfo).light, firstTimezoneJetlag.light),
    );
    if (!allLightRecommendationsAreTheSame) {
      return {
        ...base,
        jetlagStatus: JetlagStatusReport.PARTIAL_DEPENDING_ON_ZONE,
        jetlagEffectOfDirection: firstTimezoneJetlag.effectOfDirection,
        jetlagInAllZones,
      };
    }

    return {
      ...base,
      jetlagStatus: JetlagStatusReport.COMPLETE,
      jetlagEffectOfDirection: firstTimezoneJetlag.effectOfDirection,
      jetlagLightTips: firstTimezoneJetlag.light,
    };
  }

  public static getTimeOffsetNow({ name }: Timezone) {
    return OffsetUTC.now(name);
  }
}
