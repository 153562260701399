






































































































































































import Vue from 'vue';
import { CountryCode } from '@/types/CountryCode';
import { NameOfCountry } from '@/modules/NameOfCountry';
import { Platform, PlatformType } from '@/modules/Platform';
import { RawLocation } from 'vue-router';
import Highlighter from 'vue-highlight-words';
import { ColloquialCountryNames } from '@/types/CountryNames';
import { Clock } from '@/modules/time/Clock';
import { DebugOptions } from '@/store';
import { CountryRoute } from '@/router/CountryRoute';

interface ListedDestination {
  title: string;
  route: RawLocation;
  icon: string;
  country?: CountryCode;
}

interface Data {
  query: string;
  focused: boolean;
  showcase: CountryCode[];
  selection: number | undefined;
}

export default Vue.extend({
  name: 'AppBar',

  data(): Data {
    return {
      focused: false,
      query: '',
      showcase: [],
      selection: 0,
    };
  },

  computed: {
    expanded(): boolean {
      return this.$store.state.selectingDestinationCountry;
    },

    selectionModel: {
      get(): number | undefined {
        return this.focused ? this.selection : undefined; // Remove highlight if keyboard events won't do anything
      },
      set(val: number | undefined) {
        this.selection = val;
      },
    },

    maxSearchResults(): number {
      return 7;
    },
    words(): string[] {
      if (!this.trimmedQuery) {
        return [];
      }

      return this.trimmedQuery.split(' ');
    },
    origin(): CountryCode {
      return this.$store.state.home;
    },
    favourites(): CountryCode[] {
      return this.$store.state.favourites;
    },
    favouritesFiltered(): CountryCode[] {
      return this.favourites.filter((country: CountryCode) => !this.ignored.includes(country));
    },
    showcaseFiltered(): CountryCode[] {
      return this.showcase.filter((country: CountryCode) => !this.ignored.includes(country));
    },
    searchResults(): CountryCode[] {
      if (!this.trimmedQuery) {
        return [];
      }

      const result = NameOfCountry.reverseQuery(this.trimmedQuery);

      return result;
    },
    countries(): ListedDestination[] {
      const all: ListedDestination[] = [];

      const icons = {
        favourite: 'mdi-star',
        regular: 'mdi-airplane-takeoff',
        random: 'mdi-map-marker-question-outline',
      };

      const random: ListedDestination = {
        title: 'Random Country',
        route: {
          name: 'random',
          hash: this.origin ? CountryRoute.getOriginHash(this.origin) : undefined,
        },
        icon: icons.random,
      };

      if (this.searchResults.length) {
        all.push(...this.searchResults
          .map((country: CountryCode) => ({
            title: NameOfCountry.get(country),
            icon: this.favourites.includes(country) ? icons.favourite : icons.regular,
            route: CountryRoute.createRouterLink(country, this.origin),
            country,
          }))
          .sort((a, b) => {
            const query = this.trimmedQuery?.toLowerCase();
            return a.title.toLowerCase().indexOf(query) - b.title.toLowerCase().indexOf(query);
          })
          .sort((a, b) => a.title.toLowerCase().trim().length - b.title.toLowerCase().trim().length)
          .sort((a, b) => {
            if (a.icon === b.icon) {
              return 0;
            }
            if (b.icon === icons.favourite) {
              return 1;
            }
            if (a.icon === icons.favourite) {
              return -1;
            }

            return 0;
          })
          .sort((a, b) => {
            if (a.title.toLowerCase().trim() === this.trimmedQuery?.toLowerCase()) {
              return -1;
            }
            if (b.title.toLowerCase().trim() === this.trimmedQuery?.toLowerCase()) {
              return 1;
            }

            return 0; // Assume no two are an exact match at the same time
          }));
      } else if (this.favouritesFiltered.length && !this.trimmedQuery) {
        all.push(...this.favouritesFiltered
          .map((country: CountryCode) => ({
            title: NameOfCountry.get(country),
            icon: icons.favourite,
            route: CountryRoute.createRouterLink(country, this.origin),
            country,
          })));

        all.push(random);
      } else if (!this.trimmedQuery) {
        all.push(...this.showcaseFiltered
          .map((country: CountryCode) => ({
            title: NameOfCountry.get(country),
            icon: icons.regular,
            route: CountryRoute.createRouterLink(country, this.origin),
            country,
          })));

        all.push(random);
      }

      return all;
    },

    countryName(): string | undefined {
      return NameOfCountry.get(this.country);
    },

    mightBeSafari() {
      return Platform.type !== PlatformType.ANDROID; // https://github.com/vuetifyjs/vuetify/issues/12573
    },

    destination(): CountryCode | undefined {
      return this.$route?.params?.destination as CountryCode | undefined;
    },

    trimmedQuery(): string {
      return this.query.trim();
    },

    ignored(): CountryCode[] {
      const blacklist: CountryCode[] = [];

      if (this.country) {
        blacklist.push(this.country);
      }
      if (this.destination) {
        blacklist.push(this.destination);
      }

      return blacklist;
    },

    country(): CountryCode {
      return this.$store.state.home;
    },

    placeholder(): string {
      return 'Type a destination to visit';
    },

    rowsShown(): number {
      return this.trimmedQuery ? Math.min(this.countries.length, this.maxSearchResults) : this.countries.length;
    },
  },

  watch: {
    $route: {
      handler() {
        this.cancel();
      },
      immediate: true,
    },
    countries: {
      handler() {
        if (!this.countries.length) {
          return;
        }

        const canApplyCurrent = (this.selection || 0) < this.rowsShown;
        this.selection = canApplyCurrent ? (this.selection || 0) : 0;
      },
      immediate: false,
    },

    expanded: {
      handler(newValue: boolean, oldValue: boolean) {
        if (newValue && newValue !== oldValue) {
          // Focus the multibar eg. in the case where the user clicked a link to arrive here
          requestAnimationFrame(() => this.$refs.multibar.$refs.input.focus());
        }
      },
      immediate: true,
    },
  },

  methods: {
    cancel() {
      this.toggleDestinationCountryDialog(false);
      this.query = '';
      this.selection = 0;
    },
    openHomeCountryDialog() {
      this.$store.commit('toggleHomeCountryDialog', true);
    },
    toggleDestinationCountryDialog(isOpen: boolean) {
      this.$store.commit('toggleDestinationCountryDialog', isOpen);
    },

    focus(event: Event) {
      event.preventDefault();
      this.focused = true;
      this.toggleDestinationCountryDialog(true);
    },

    changedSelection() {
      if (typeof this.selection === 'undefined' && this.countries.length > 0) {
        this.$nextTick(() => {
          this.selection = 0;
        });
      }
    },

    moveSelection(direction: number, eventToCancel?: Event) {
      if (eventToCancel) {
        eventToCancel.preventDefault();
      }

      this.selection = Clock.mod((this.selection || 0) + direction, this.rowsShown);
    },

    pressedEnter(event: { target: HTMLInputElement}) {
      this.select();
      event.target.blur(); // IOS doesn't deselect
    },

    select() {
      if (this.trimmedQuery === 'debugger') {
        const debugged: DebugOptions = {
          autoDetectCountry: true,
        };
        this.$store.commit('toggleDebugging', debugged);
        this.cancel();
        console.info('Debugger enabled');
        this.openHomeCountryDialog();
        return;
      }

      if (typeof this.selection === 'undefined') {
        return;
      }

      const place = this.countries[this.selection]; // Save this so that cancellation doesn't affect it
      if (!place) {
        return;
      }

      this.cancel();
      this.$router.push(place.route, undefined, (error: Error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
    },
  },

  created() {
    const howMany = 3;

    const showcase = Object.keys(ColloquialCountryNames) as CountryCode[];

    if (howMany > showcase.length - 1) {
      // Sanity check
      throw new Error('Not enough countries to showcase');
    }

    const shuffled = showcase
      .map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value)
      .filter((country) => !this.ignored.includes(country));

    this.showcase = shuffled.slice(0, howMany);

    // Make back button close the dialog
    const unregisterRouterGuard = this.$router.beforeEach((to: unknown, from: unknown, next: (to?: false) => void) => {
      if (this.expanded) {
        this.cancel();
        next(false);
      } else {
        next();
      }
    });

    this.$once('hook:destroyed', () => {
      // This will be called when the component is destroyed.
      unregisterRouterGuard();
    });
  },

  components: {
    Highlighter,
  },
});
